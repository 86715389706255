














































































































































































































import { defineComponent, ref, computed, Ref } from '@vue/composition-api';
import { useBirthdayMessages } from '@/composition/birthdayMessages';
import { useNotification } from '@/composition/notification';
import FcCheckFilter from '@/components/FcCheckFilter.vue';
import core from '@/admin/core';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';
import { User } from '@/admin/user';
import { useUser } from '@/admin/user';
import { removeStorage } from '@/admin/util';

export default defineComponent({
  name: 'Birthday',
  components: {
    FcCheckFilter,
    FcRoleLoading,
    FcRoleDeny,
  },
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('birthdayMessages'));
    const { warning, notify, error } = useNotification();

    const isFetchLoading = ref(true);
    const testUsers: Ref<User[]> = ref([]);
    const selectedTestUsers: Ref<User[]> = ref([]);
    const storageIds: Ref<string[]> = ref([]);
    const { birthdayMessages } = useBirthdayMessages();
    const { getTestUsers } = useUser();
    const targetNotificationReservationId = ref('');

    const tableHeight = ref(600);
    const elements = document.getElementsByClassName('v-main__wrap');
    if (elements.length) {
      tableHeight.value = elements[0].clientHeight - 64 - 40;
    }

    const headers = [
      { text: 'ステータス', value: 'notificationReservationStatus' },
      { text: '作成日', value: 'createDate.dateTime' },
      { text: '管理名', value: 'templateName', width: '400px' },
      { text: '件名', value: 'title' },
      { text: '公開日時', value: 'releaseDate.dateTime' },
      { text: '', value: 'actions', sortable: false, align: 'end', width: '200px' },
    ];

    // フィルター
    // ステータス
    const selectedStatusValues: Ref<string[]> = ref([]);
    const statuses = [
      { text: '下書き', value: 'draft' },
      { text: '予約中', value: 'scheduled' },
      { text: '有効', value: 'active' },
      { text: 'アーカイブ', value: 'archive' },
    ];

    // フリーワード検索
    const searchWord = ref('');
    // アーカイブを表示するかどうかのフィルター
    const isDisplayArchiveItem = ref(false);

    const displayBirthdayMessages = computed(() => {
      return birthdayMessages.items
        .filter((item) =>
          isDisplayArchiveItem.value ? isDisplayArchiveItem.value : item.notificationReservationStatus !== 'archive'
        )
        .filter(
          (item) =>
            selectedStatusValues.value.length === 0 ||
            selectedStatusValues.value.includes(item.notificationReservationStatus)
        )
        .filter((item) => !searchWord.value || JSON.stringify(item).includes(searchWord.value));
    });

    // メールアドレス検索
    const searchEmail = ref('');

    // 表示ユーザー一覧
    const displayTestUsers = computed(() => {
      return testUsers.value.filter((testUser) => testUser.email.includes(searchEmail.value));
    });

    // 即時テスト送信
    const isActiveDialog = ref(false);
    const isSending = ref(false);

    const openDialog = (notificationReservationId: string) => {
      targetNotificationReservationId.value = notificationReservationId;
      isActiveDialog.value = true;
    };

    const closeDialog = () => {
      targetNotificationReservationId.value = '';
      isActiveDialog.value = false;
    };

    const sendTestMessage = async () => {
      isSending.value = true;
      const targetBirthdayMessage = birthdayMessages.getBirthdayMessage(targetNotificationReservationId.value);
      if (!targetBirthdayMessage) return notify('存在しない通知IDです');
      const testUsernames = selectedTestUsers.value.map((testUser) => testUser.userId);
      try {
        await targetBirthdayMessage.sendTestBirthdayMessage(testUsernames);
        notify('テスト送信が完了しました');
      } catch (e) {
        error(e);
      } finally {
        isSending.value = false;
        isActiveDialog.value = false;
      }
    };

    // テストユーザー一覧
    const headersTestUser = [
      { text: 'ユーザーID', value: 'userId' },
      { text: 'メールアドレス', value: 'email' },
    ];

    // 複製
    const copyMessage = async (notificationReservationId: string) => {
      try {
        const targetBirthdayMessage = birthdayMessages.getBirthdayMessage(notificationReservationId);
        if (!targetBirthdayMessage) return notify('存在しない通知IDです');
        await targetBirthdayMessage.copy();
        await birthdayMessages.getBirthdayMessages();
        notify('通知を複製しました。');
      } catch (e) {
        error(e);
      }
    };

    // 削除
    const deleteBirthdayMessage = async (notificationReservationId: string) => {
      if (!confirm('本当に削除しますか？')) return;
      try {
        const targetBirthdayMessage = birthdayMessages.getBirthdayMessage(notificationReservationId);
        if (!targetBirthdayMessage) return notify('存在しない通知IDです');
        await targetBirthdayMessage.delete();
        removeStorage(`birthdayNotificationId:${notificationReservationId}`);
        await birthdayMessages.getBirthdayMessages();
        notify('削除しました。');
      } catch (e) {
        error(e);
      }
    };

    // もっと読み込む
    const isNextItemLoading = ref(false);
    const getNextMessages = async () => {
      isNextItemLoading.value = true;
      try {
        await birthdayMessages.getNextBirthdayMessages();
      } catch (e) {
        error(e);
      } finally {
        isNextItemLoading.value = false;
      }
    };

    const init = async () => {
      isFetchLoading.value = true;
      try {
        await birthdayMessages.init();
        testUsers.value = await getTestUsers();
        // localStorageの確認
        for (let i = 0, length = window.localStorage.length; i < length; ++i) {
          const key = localStorage.key(i) || '';
          if (key.includes('birthdayNotificationId:')) {
            const birthdayNotificationId = key.replace('birthdayNotificationId:', '');
            storageIds.value.push(birthdayNotificationId);
          }
        }
        const notificationMessage = storageIds.value.length ? '編集中のデータがあります' : '';
        if (notificationMessage) warning(notificationMessage, { timeout: 10000, icon: 'mdi-pencil' });
      } catch (e) {
        error(e);
      } finally {
        isFetchLoading.value = false;
      }
    };

    init();

    return {
      pageTitle: '誕生日メッセージ',
      myRoleSettings,
      hostingUrl: core.hostingUrl,
      headers,
      selectedStatusValues,
      statuses,
      searchWord,
      displayBirthdayMessages,
      nextToken: birthdayMessages.nextToken,
      isActiveDialog,
      openDialog,
      closeDialog,
      headersTestUser,
      displayTestUsers,
      searchEmail,
      selectedTestUsers,
      isSending,
      sendTestMessage,
      copyMessage,
      deleteBirthdayMessage,
      tableHeight,
      isDisplayArchiveItem,
      isNextItemLoading,
      getNextMessages,
      storageIds,
    };
  },
});
