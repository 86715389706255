import core from '@/admin/core';

const notificationReservationStatusResponse = ['draft', 'scheduled', 'archive', 'active'] as const;
export type NotificationReservationStatusResponse = typeof notificationReservationStatusResponse[number];

// 一件取得レスポンス
export interface BirthdayMessageResponse {
  notificationReservationId: string;
  notificationType: 'message';
  notificationReservationType: 'birthday';
  notificationReservationStatus: NotificationReservationStatusResponse;
  createDate: number;
  releaseDate?: number;
  title: string;
  templateName: string;
  body01?: string;
  body02?: string;
  image01?: string;
  image02?: string;
  linkUrl?: string;
  linkText?: string;
  isExternalLink?: boolean;
  isRestricted?: boolean;
}

// 一覧取得時レスポンス
interface BirthdayMessagesResponse {
  notificationReservations: BirthdayMessageResponse[];
  nextToken?: string;
}

// 新規作成/更新時リクエストボディ
export type BirthdayMessageRequestProps = Omit<
  BirthdayMessageResponse,
  'createDate' | 'notificationReservationId' | 'isRestricted'
>;

export interface SendBirthdayMessageToTestUsersRequest {
  notificationReservationId: string;
  testUsernames: string[];
} 

export const createBirthdayMessage = async (props: BirthdayMessageRequestProps) => {
  await core.httpClient.post('/admin/public/birthdayNotificationReservations', props);
};

export const editBirthdayMessage = async (notificationReservationId: string, props: BirthdayMessageRequestProps) => {
  await core.httpClient.put(`/admin/public/birthdayNotificationReservations/${notificationReservationId}`, props);
};

export const copyBirthdayMessage = async (notificationReservationId: string) => {
  await core.httpClient.post(`/admin/public/birthdayNotificationReservations/${notificationReservationId}`);
};

export const sendBirthdayMessageToTestUsers = async (props: SendBirthdayMessageToTestUsersRequest) => {
  await core.httpClient.post(`/admin/public/birthdayNotificationReservations/notifications`, props);
};

export const getBirthdayMessages = async (nextToken?: string) => {
  const result = await core.httpClient.get(
    `/admin/public/birthdayNotificationReservations${nextToken ? `&nextToken=${nextToken}` : ''}`
  );
  return result.data as BirthdayMessagesResponse;
};

export const getBirthdayMessage = async (notificationReservationId?: string) => {
  if (!notificationReservationId) return null;
  const result = await core.httpClient.get(
    `/admin/public/birthdayNotificationReservations/${notificationReservationId}`
  );
  return result.data as BirthdayMessageResponse;
};

export const deleteBirthdayMessages = async (notificationReservationId: string) => {
  await core.httpClient.delete(`/admin/public/birthdayNotificationReservations/${notificationReservationId}`);
};
