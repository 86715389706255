import {
  editBirthdayMessage,
  copyBirthdayMessage,
  getBirthdayMessage,
  getBirthdayMessages,
  deleteBirthdayMessages,
  BirthdayMessageResponse,
  BirthdayMessageRequestProps,
  createBirthdayMessage,
  sendBirthdayMessageToTestUsers,
  SendBirthdayMessageToTestUsersRequest,
} from '@/admin/birthdayMessages';
import { reactive } from '@vue/composition-api';
import { DisplayDate, valueOf } from '@/admin/util';

type BirthdayNotificationReservationType = 'birthday';
type BirthdayNotificationType = 'message';
const notificationReservationStatusJson = {
  draft: '下書き',
  scheduled: '公開予約',
  active: '有効',
  archive: 'アーカイブ',
} as const;
type NotificationReservationDisplayStatus = valueOf<typeof notificationReservationStatusJson>;
type NotificationReservationStatus = keyof typeof notificationReservationStatusJson;

interface BirthdayMessageItem {
  notificationReservationStatus: NotificationReservationStatus;
  releaseDate: DisplayDate;
  title: string;
  body01: string | undefined;
  body02: string | undefined;
  image01: string | undefined;
  image02: string | undefined;
  linkUrl: string | undefined;
  linkText: string | undefined;
  isExternalLink: boolean | undefined;
}

// 誕生日メッセージテンプレート基本
class BirthdayMessageBase {
  notificationReservationId: string;
  notificationReservationType: BirthdayNotificationReservationType;
  notificationReservationDisplayStatus: NotificationReservationDisplayStatus;
  notificationType: BirthdayNotificationType;
  createDate: DisplayDate;
  templateName: string;
  item: BirthdayMessageItem;
  constructor(response: BirthdayMessageResponse) {
    this.notificationReservationId = response.notificationReservationId;
    this.notificationReservationType = response.notificationReservationType;
    this.notificationReservationDisplayStatus =
      notificationReservationStatusJson[response.notificationReservationStatus];
    this.notificationType = response.notificationType;
    this.createDate = new DisplayDate(response.createDate);
    this.templateName = response.templateName;
    this.item = {
      notificationReservationStatus: response.notificationReservationStatus,
      releaseDate: new DisplayDate(response.releaseDate),
      title: response.title,
      body01: response.body01,
      body02: response.body02,
      image01: response.image01,
      image02: response.image02,
      linkUrl: response.linkUrl,
      linkText: response.linkText,
      isExternalLink: response.isExternalLink,
    };
  }
}

export interface BirthdayStorageValue {
  createDate: string;
  id: string;
  item: BirthdayMessageBase;
}

// 一件表示用
export class BirthdayMessage implements BirthdayMessageBase {
  // BirthdayMessageBaseの初期値
  notificationReservationId = '';
  notificationReservationType: BirthdayNotificationReservationType = 'birthday';
  notificationReservationDisplayStatus: NotificationReservationDisplayStatus = '下書き';
  notificationType: BirthdayNotificationType = 'message';
  createDate = new DisplayDate();
  templateName = '';
  // ユーザーが変更する項目は編集を監視するためにitemとしてまとめる
  item: BirthdayMessageItem = {
    notificationReservationStatus: 'draft',
    releaseDate: new DisplayDate(),
    title: '',
    body01: '',
    body02: '',
    image01: '',
    image02: '',
    linkUrl: undefined, // watchによりundefinedになるため、初期値もundefinedにする
    linkText: undefined, // watchによりundefinedになるため、初期値もundefinedにする
    isExternalLink: false,
  };

  // 以下画面表示制御用パラメータ
  isAllowInput = true;

  storageKey: string;

  constructor(notificationReservationId?: string) {
    // 固定値を入れておく
    this.notificationReservationType = 'birthday';
    this.notificationType = 'message';

    if (!notificationReservationId) {
      this.storageKey = 'birthdayNotificationId:newCreate';
    } else {
      this.notificationReservationId = notificationReservationId;
      this.storageKey = `birthdayNotificationId:${notificationReservationId}`;
    }
  }

  // 取得
  async getBirthdayMessage() {
    const birthdayMessage = await getBirthdayMessage(this.notificationReservationId);
    if (birthdayMessage) {
      const props = new BirthdayMessageBase(birthdayMessage);
      Object.assign(this, props);
      // 入力可能なのは新規作成時(notificationReservationIdなし)と下書きのステータス(draft)の場合のみ
      this.isAllowInput =
        !birthdayMessage.notificationReservationId || birthdayMessage.notificationReservationStatus === 'draft';
    }
    // 新規作成時でデータが取得できなかった場合は何もしない（Object.assignで変更すると別のオブジェクトとしてwatchで検知されるうえ、初期値を更新する意味もないため）
  }

  // 新規作成
  async create() {
    await createBirthdayMessage(this.getRequestProps());
  }

  // 更新
  async update() {
    await editBirthdayMessage(this.notificationReservationId, this.getRequestProps());
  }

  // 下書きへの更新
  async updateDraftFromScheduled() {
    if (this.item.notificationReservationStatus !== 'scheduled') {
      throw '公開予約以外の誕生日テンプレートは下書きにできません';
    }
    // ステータスの更新と公開日時のリセット
    this.item.notificationReservationStatus = 'draft';
    this.item.releaseDate = new DisplayDate();
    await editBirthdayMessage(this.notificationReservationId, this.getRequestProps());
  }

  // 更新・新規作成用のprops作成
  private getRequestProps(): BirthdayMessageRequestProps {
    if (this.item.notificationReservationStatus === 'active' || this.item.notificationReservationStatus === 'archive') {
      throw '有効もしくはアーカイブの誕生日テンプレートは更新および新規作成できません';
    }

    let error = '';
    // バリデート
    const now = Date.now();
    // 必須項目チェック
    if (
      !this.item.title ||
      !this.templateName ||
      !this.item.notificationReservationStatus ||
      !this.notificationReservationType
    ) {
      error += '件名，管理名，ステータスで未入力があります';
    }
    // 件名
    if (this.item.title.match(/\{*nickname\}*/g)) {
      error += '件名に nickname は使用できません';
    }
    // リンク
    if (this.item.isExternalLink) {
      // 必須チェック
      if (!this.item.linkUrl || !this.item.linkText) {
        error += 'リンク先を使用する場合はURLとリンクテキストが必須です';
      }
      // URLバリデーション
      if (this.item.linkUrl && !this.item.linkUrl.match(/^(https?|ftp)(:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)$/)) {
        error += 'URLが不正です。ドメインから正しく指定してください';
      }
    } else {
      // 本文入力チェック
      if (!this.item.body01 && !this.item.body02) {
        error += 'リンク先を使用しない場合は本文の入力が必須です';
      }
    }

    // 公開予約日時チェック
    if (this.item.notificationReservationStatus === 'scheduled') {
      if (this.item.releaseDate.value === undefined) {
        error += '公開予約日時を正しく入力してください';
      }
      if (this.item.releaseDate.value && this.item.releaseDate.value < now) {
        error += '公開予約日時が現在日時より前に設定されています';
      }
    }
    if (error) throw error;
    // .もしくは/でないとサファリでバグってしまう

    let releaseDate: Date | undefined;
    if (this.item.releaseDate.value) releaseDate = new Date(`${this.item.releaseDate.format()} 10:00:00`);
    const props: BirthdayMessageRequestProps = {
      notificationReservationStatus: this.item.notificationReservationStatus,
      notificationReservationType: this.notificationReservationType,
      notificationType: this.notificationType,
      releaseDate: releaseDate ? releaseDate.getTime() : undefined,
      title: this.item.title,
      templateName: this.templateName,
      body01: this.item.body01,
      body02: this.item.body02,
      image01: this.item.image01,
      image02: this.item.image02,
      linkUrl: this.item.linkUrl,
      linkText: this.item.linkText,
      isExternalLink: this.item.isExternalLink,
    };
    return props;
  }

  // ストレージの値を格納
  setStorageValue(item: BirthdayMessageBase) {
    // 一度そのままデータを割り当て、コンストラクタや処理が必要な値のみ上書きする
    Object.assign(this, item);
    this.createDate = new DisplayDate(item.createDate.value);
    this.item.releaseDate = new DisplayDate(item.item.releaseDate.value);

    // item.item.notificationReservationStatusで判定すると、保存されていない状態でもisAllowInputがfalseになってしまうため、item.notificationReservationDisplayStatusで判定する
    // また、新規作成時(notificationReservationIdなし)なのか確認しなくても、同じ条件で判定可能
    this.isAllowInput = item.notificationReservationDisplayStatus === '下書き';
  }
  // ストレージ保存用のデータを返す
  getNewStorageValue() {
    // thisから画面表示用のパラメータを除いたデータ(BirthdayMessageBase)を取得
    const item = (({ isAllowInput, storageKey, ...rest }) => rest)(this);
    const newStorageValue: BirthdayStorageValue = {
      createDate: DisplayDate.now().dateTime,
      id: '',
      item: item,
    };
    return newStorageValue;
  }
}

// 一覧表示時の一件ずつのデータ
export class BirthdayMessagesDisplayData {
  notificationReservationId = '';
  notificationReservationStatus: NotificationReservationStatus = 'draft';
  notificationReservationDisplayStatus: NotificationReservationDisplayStatus = '下書き';
  title = '';
  templateName = '';
  releaseDate = new DisplayDate();
  createDate = new DisplayDate();

  constructor(props: BirthdayMessageResponse) {
    this.notificationReservationId = props.notificationReservationId;
    this.notificationReservationStatus = props.notificationReservationStatus;
    this.notificationReservationDisplayStatus = notificationReservationStatusJson[props.notificationReservationStatus];
    this.title = props.title;
    this.templateName = props.templateName;
    this.releaseDate = new DisplayDate(props.releaseDate);
    this.createDate = new DisplayDate(props.createDate);
  }

  async sendTestBirthdayMessage(testUsernames: string[]) {
    const props: SendBirthdayMessageToTestUsersRequest = {
      notificationReservationId: this.notificationReservationId,
      testUsernames: testUsernames,
    };
    await sendBirthdayMessageToTestUsers(props);
  }

  // 複製
  async copy() {
    await copyBirthdayMessage(this.notificationReservationId);
  }

  // 削除
  async delete() {
    await deleteBirthdayMessages(this.notificationReservationId);
  }
}

// 一覧表示時のデータ
class BirthdayMessages {
  items: BirthdayMessagesDisplayData[] = [];
  nextToken?: string;
  async init() {
    await this.getBirthdayMessages();
  }

  // 指定されたIDのテンプレート取得
  getBirthdayMessage(notificationReservationId: string) {
    return this.items.find((item) => item.notificationReservationId === notificationReservationId);
  }

  // 一覧取得
  async getBirthdayMessages(nextToken?: string) {
    const birthdayMessagesResponse = await getBirthdayMessages(nextToken);
    const items = birthdayMessagesResponse.notificationReservations.map(
      (item) => new BirthdayMessagesDisplayData(item)
    );
    // 有効→予約→下書き→アーカイブの順(それぞれ作成日順)に整列
    const activeItems: BirthdayMessagesDisplayData[] = [];
    const scheduledItems: BirthdayMessagesDisplayData[] = [];
    const draftItems: BirthdayMessagesDisplayData[] = [];
    const archiveItems: BirthdayMessagesDisplayData[] = [];
    items.forEach((item) => {
      if (item.notificationReservationStatus === 'active') activeItems.push(item);
      else if (item.notificationReservationStatus === 'scheduled') scheduledItems.push(item);
      else if (item.notificationReservationStatus === 'draft') draftItems.push(item);
      else if (item.notificationReservationStatus === 'archive') archiveItems.push(item);
    });
    this.items = activeItems
      .concat(scheduledItems)
      .concat(draftItems)
      .concat(archiveItems);
    this.nextToken = birthdayMessagesResponse.nextToken;
  }

  // 次ページ読み込み
  async getNextBirthdayMessages() {
    await this.getBirthdayMessages(this.nextToken);
  }
}

export const useBirthdayMessage = (notificationReservationId?: string) => {
  const birthdayMessage = new BirthdayMessage(notificationReservationId);
  return { birthdayMessage: reactive(birthdayMessage) };
};

export const useBirthdayMessages = () => {
  const birthdayMessages = new BirthdayMessages();
  return { birthdayMessages: reactive(birthdayMessages) };
};
